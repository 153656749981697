:root {
  --main-bg-colour: #aacafb;
  --colour-red: #b21c1c;
  --colour-highlight: #ffd500;
  --colour-section-bg: #fff5e0;
  --article-margins: 7%;
  --margin-top: 2em;
  --margin-colour-box: 2%;
  --dynamic-margin-top: 0px;
  --title-font: "Freeman", sans-serif;
  --article-headers-font: "Freeman", sans-serif;
  --link-header-font: "Freeman", sans-serif;
  --subheader-font: "Poetsen One", sans-serif;
}

/* SUCCESSFUL PAYMENT */
#success-wrapper {
  background-color: var(--main-bg-colour);
  /* Fallback for browsers without fill-available */
  height: 100vh;
  /* For WebKit-based */
  height: -webkit-fill-available;
  /* For old Firefox */
  height: -moz-available;
  /* Unofficial draft name */
  height: fill-available;

  width: 100%;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

#success {
  z-index: 200;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 85%;
  text-align: center;
  margin: 50px auto 50px auto;
  display: grid;
  justify-content: center;
  align-content: stretch;
}

#success h2 {
  padding: 0;
  margin: 0;
  font-size: 2em;
}

#joyful-image {
  max-width: 75%;
  margin: 15px auto 10px auto;
}

/* TYPOGRAPHY */
h1, h1 span {
  font-size: 8vh;
  /*font-variant: small-caps;*/
  margin: auto;
  text-transform: uppercase;
}

h2, h3 {
  text-align: center;
}

section {
  font-weight: 600 !important;
}

main {
  scroll-behavior: smooth;
}

#navHeader {
  font-weight: 800;
}

a {
  text-decoration: none;
  transition: color 0.3s ease;
  color: black;
}

a:hover {
  transition: color 0.3s ease;
  color: white;
  cursor: pointer !important;
}

.fadeLinkColor {
  color: white;
  transition: color 0.3s ease-out;
}

#divLinksNav p a:hover {
  transition: color 0.3s ease;
  color: var(--colour-red);
}

#hyp-span {
  text-transform: uppercase;
  font-size: 72px;
  font-family: 'Verdana';
}

/* CHECKOUT */
#checkout {
  display: none;
}

#checkout * {
  transition: all 0.3s ease;
}

#checkout div {
  height: fit-content;
  overflow: auto;
}

#checkout-inner-wrapper {
  max-height: 95vh;
}

#close-button-wrapper {
  width: 100%;
  padding: 10px 0px 10px 0px;
  background-color: white;
}

#close-button {
  width: 90%;
  border-radius: 7px;
  margin: auto;
  border: white;
  font-family: "Arimo";
  font-weight: 800 !important;
  font-size: medium !important;
  padding: 13px 17px !important;
}

#section-1 {
  display: none;
}

#header-buy-button button, #nav-button-li button {
  background: #4790ff;
  color: #ffffff;
}

/* GRID */
main {
  display: grid;
  grid-template-areas:
    'header header header header header header header header header header header header'
    'content content content content content content content content content content content content'
    'footer footer footer footer footer footer footer footer footer footer footer footer';
  grid-template-columns: 20% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 20%;
}

header {
  grid-area: header;
  grid-template-rows: auto 1fr 65px;
  /* Fallback for browsers without fill-available */
  height: 100vh;
  height: -webkit-fill-available;
  height: -moz-available;
  height: fill-available;

  width: 100%;
  position: absolute;
  background-color: var(--main-bg-colour);
  box-shadow: 0px 5px 4px #00000026;
  z-index: 100;
  display: grid;
}

article {
  grid-area: content;
  grid-column: 2 / 12;
  padding: 0px 0 0px 0;
  margin: var(--dynamic-margin-top) 0 2em 0;
  display: grid;
  height: fit-content;

  /* Fallback if subgrid is not supported */
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

/* If a browser supports subgrid */
@supports (grid-template-columns: subgrid) {
  article {
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
  }
}

article div img {
  margin: auto;
  display: block;
  margin-top: 120px;
  max-width: 90%;
}

footer {
  grid-area: footer;
  bottom: 0;

  /* Fallback if subgrid is not supported */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);

  background-color: var(--main-bg-colour);
  box-shadow: 0px 8px 19px #00000029 inset;
  padding: 5px 0 5px 0;
}

@supports (grid-template-columns: subgrid) {
  footer {
    grid-template-columns: subgrid;
    grid-template-rows: repeat(3, 1fr);
  }
}

/* NAV */
ul {
  list-style-type: none;
  margin: auto;
  font-weight: bold;
  font-size: larger;
  transition: all 0.3s ease;
}

li {
  transition: all 0.3s ease;
}

nav {
  transition: all 0.3s ease;
  position: sticky;
  grid-row-start: 3;
  grid-column-start: 1;
  grid-column-end: 12;
  display: flex;
}

li a {
  padding: 16px;
}

nav {
  flex-wrap: nowrap;
}

/* HEADER */
#header-double {
  position: absolute;
  /* Fallback for browsers without fill-available */
  min-height: 100vh;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: fill-available;

  width: 100%;
}

/* ARTICLE */
#sections-wrapper {
  grid-area: inherit;
  grid-row: 2/3;
  grid-column: 1 / 13;
  background-color: transparent;
}

.divYtContainer {
  display: block;
  background-color: transparent;
}

.divYtContainer iframe {
  border: none;
}

#ytVideo {
  display: flex;
  margin: 20px auto 30px auto;
  aspect-ratio: 16 / 9;
  height: 100%;
  border-width: 0;
}

.noise {
  background-image: url(images/bg_images/noise.webp);
}

.noiseM {
  background-image: url(images/bg_images/noiseM.webp);
}

#termsBody {
  display: grid;
  grid-area: content;
  grid-column: 2 / 12;
  background-color: #fff5e0;
  padding: 70px;
  border-radius: 100px;
  margin: 50px 0 50px 0;
  background-image: url(images/bg_images/noiseH.webp);
}

/* BUBBLES */
section {
  padding: 3em 5% 4em 5%;
  background-image: url(images/bg_images/noiseH.webp);
  margin: 90px auto 90px auto;
  max-width: 600px;
  box-shadow: 8px 10px 4px #00000014;
  border-radius: 50px;
  background-color: var(--colour-section-bg);
  position: relative;
  font-weight: bold;
}

#anchorAboutBook {
  margin-top: 40px;
}

h2 {
  font-size: 1.6em;
  padding: 0.2em 0 1.5em 0;
}

#countdown-timer h2 {
  padding: 2em 0 0em 0 !important;
  font-size: 3em !important;
}

#countdown-timer h1, #subscribeHeader {
  padding: 0 0 0 0 !important;
  font-size: 2.5em !important;
  text-align: center;
}

#countdown-timer h1 span,
#subscribeHeader {
  font-size: inherit;
}

#countdown-timer h1 span:nth-child(1) {
  color: grey;
}

#subscribeHeader {
  margin-top: 50px;
}

.mc-field-group {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
}

#mce-EMAIL {
  text-align: center;
  height: 25px;
  border-radius: 10px;
  border: 2px solid #afafaf;
}

#mce-EMAIL[type=text]:focus {
  border: 3px solid var(--main-bg-colour);
}

/* IMAGES */
#image_1 {
  aspect-ratio: 200 / 179;
}

#image_2 {
  aspect-ratio: 160 / 137;
}

#image_3 {
  aspect-ratio: 789 / 800;
}

#image_4 {
  width: 100%;
  height: auto;
  max-width: 25%;
  aspect-ratio: 350 / 269;
  display: block;
  margin: auto;
  margin-top: -40px;
}

#image_1, #image_2, #image_3 {
  margin: 0px auto 25px auto;
  max-width: 75%;
  display: block;
  width: 100%;
  height: auto;
}

#socials-wrapper {
  margin: 20px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socials-icon {
  width: 25px;
  margin: 0px 5px 0px 5px !important;
  padding: 0px !important;
}

#is-icon, #yt-icon, #tk-icon {
  aspect-ratio: 1 / 1;
}

#ds-icon {
  aspect-ratio: 25 / 19;
}

#yt-icon {
  width: 30px !important;
}

/* NAVBAR */
.navbar-ul {
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  margin: auto;
  font-size: x-large;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-ul a {
  margin: 0 1em 0 1em;
  font-size: x-large;
}

#navbar-ul-mobile a {
  margin: 3px 0 3px 0;
  padding: 0 .5em 0 .5em;
  font-size: 2.5vh;
}

#navbar-ul-desktop {
  margin-bottom: 30px;
}

#nav-button-li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#nav-button-li h3 {
  padding: 5px 20px 0 30px;
  text-transform: uppercase;
  font-family: 'Verdana';
  display: none;
}

#nav-button-li h3 span {
  text-transform: uppercase;
  font-family: var(--title-font);
}

#nav-button {
  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  max-width: 0px;
  display: none;
  font-size: x-large;
  padding: 11px 18px;
  margin: 0px 20px 0px 0px;
  right: 0;
}

.nav-header-w1_2 {
  z-index: 100;
  position: relative;
}

nav {
  flex-wrap: nowrap;
}

#navbar-ul-mobile {
  overflow: visible;
  padding: 0 0 0 0;
  display: none;
  height: 115px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  z-index: 1;
}

#closePrivacyPolicy,
#closePageSpread,
#closeTermsOfUse {
  background-color: #ffebcd00;
  font-size: 4em;
  color: black;
  padding: 0;
  margin: 0 12px 0 0;
  float: right;
}

.scroll-shadow {
  background:
    /* Shadow covers */
    linear-gradient(to right, var(--main-bg-colour) 30%, rgba(255, 255, 255, 0)),
    linear-gradient(to left, var(--main-bg-colour) 30%, rgba(255, 255, 255, 0)) 100% 0,
    /* Shadows */
    radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 100% 0;
}

#navbar-ul-desktop {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

#navHeader {
  left: 0;
}

/* FOOTER */
footer p,
footer a {
  color: black;
  font-family: 'Noto Sans', sans-serif;
  font-weight: 600;
}

.footerLinksDiv {
  text-align: right;
}

.footerCopyrightDiv {
  text-align: left;
}

.footerCopyrightDiv {
  grid-column: 3 / 6;
  grid-row: 2 / 3;
}

.footerCopyrightDiv p {
  margin: 0;
}

.footerLinksDiv {
  overflow: visible;
  grid-column: 6 / 11;
  grid-row: 2 / 3;
}

#privacyPolicyDialogWrapper,
#pageSpreadDialogWrapper,
#termsOfUseDialogWrapper {
  /* Removed position: absolute; to avoid conflicts with position: fixed */
  background-color: #a0a0a06e;
  position: fixed;
  overflow: auto; /* replaced overflow: overlay */
  width: 100%;
  height: 100%;
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  text-align: left !important;
  margin: auto;
}

#privacyPolicyDialog,
#pageSpreadDialog,
#termsOfUseDialog {
  background-color: var(--colour-section-bg);
  background-image: url(images/bg_images/noiseH.webp);
  box-shadow: 8px 10px 4px #00000014;
  border-radius: 50px;
  width: 70%;
  height: 90%;
  overflow: auto; /* replaced overlay */
}

#pageSpreadDialog {
  max-width: 700px;
  padding-bottom: 100px;
}

#pageSpreadDialog img {
  margin: 90px auto -50px;
  filter: drop-shadow(3px 5px 3px #aeaeae);
}

#spreadPreviewText {
  text-align: center;
  margin-bottom: 30px;
  margin-top: -30px;
  color: grey;
}

#privacyPolicyDialog li, 
#privacyPolicyDialog p, 
#privacyPolicyDialog h1, 
#privacyPolicyDialog h2, 
#privacyPolicyDialog h3,
#termsOfUseDialog li, 
#termsOfUseDialog p, 
#termsOfUseDialog h1, 
#termsOfUseDialog h2, 
#termsOfUseDialog h3 {
  padding-left: 2em;
  padding-right: 2em;
}

#privacyPolicyDialog p,
#termsOfUseDialog p {
  font-family: "Sans", sans-serif;
}

#privacyPolicyDialog ul,
#termsOfUseDialog ul {
  font-size: inherit;
  font-weight: normal;
  font-family: var(--link-header-font);
  margin-top: .5em;
  margin-bottom: 1em;
}

#privacyPolicyDialog li,
#termsOfUseDialog li {
  font-size: inherit;
  list-style: inside;
  float: none !important;
}

#privacyPolicyDialog h2,
#termsOfUseDialog h2 {
  padding-top: 3em;
  padding: 2.5em 2em 1em 2em !important;
}

#privacyPolicyDialog h1,
#termsOfUseDialog h1 {
  text-align: center !important;
  font-size: 6vh;
  padding-bottom: 1em;
  padding-top: 0.5em;
}

#privacyPolicyLastP,
#termsOfUseLastP {
  padding-bottom: 5em;
}

#image_0 {
  margin: 0px auto 20px auto;
  cursor: pointer;
}

/* RESPONSIVE BREAKPOINTS */
@media only screen and (min-width: 1700px) {
  .navbar-ul {
    font-size: x-large;
  }
}

#header-hl:before {
  content: " ";
  display: block;
  height: 60%;
  width: 93%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: #ffd500;
  transform: rotate(1deg);
  top: -2px;
  left: 4px;
  border-radius: 20% 25% 20% 24%;
  padding: 10px 3px 3px 10px;
}

/* MOBILE */
@media only screen and (max-width: 1100px) {
  #privacyPolicyDialog {
    width: 90%;
  }

  nav {
    display: flex;
    align-content: space-around;
    overflow: visible;
    white-space: nowrap;
  }

  #navHeader {
    font-size: large;
  }

  nav a {
    padding: 0 1em 0 1em;
  }

  main {
    grid-template-columns: 0% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0%;
  }

  header {
    grid-template-rows: auto 2fr 1fr;
  }

  #navbar-ul-desktop {
    display: none;
  }

  #joyful-image {
    max-width: 85%;
    max-height: 100%;
  }

  a:hover {
    color: black;
  }

  #checkout-inner-wrapper {
    max-height: 80vh;
  }

  h1, h1 span {
    font-size: 6vh;
  }

  h2 {
    font-size: 1.4em;
  }

  #nav-button-li h3 {
    padding: 0 20px 0 20px;
  }

  #anchorAboutBook {
    margin-top: 0vh;
  }

  #image_1, #image_2, #image_3 {
    max-width: 90%;
  }

  #image_4 {
    max-width: 50%;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  section {
    padding: 2em 5% 2em 5%;
    margin: 65px auto 50px auto;
    width: 80%;
  }

  #anchorCountdownTimer {
    margin-top: 30px !important;
  }

  #header-hl:before {
    content: " ";
    display: block;
    height: 60%;
    width: 96%;
    margin-left: -3px;
    margin-right: -3px;
    position: absolute;
    background: #ffd500;
    transform: rotate(1deg);
    top: -2px;
    left: 4px;
    border-radius: 20% 25% 20% 24%;
    padding: 10px 3px 3px 10px;
  }

  .highlight-container:before {
    content: " ";
    display: block;
    height: 90%;
    width: 100%;
    margin-left: -14px;
    margin-right: -3px;
    position: absolute;
    background: #ffd500;
    transform: rotate(1deg);
    top: -2px;
    left: -1px;
    border-radius: 16% 11% 20% 10%;
    padding: 4px 23px 3px 10px;
  }

  .highlight-container3:before {
    width: 130%;
  }
  
  .highlight-mob:before {
    height: 75%;
  }

  #navbar-ul-mobile {
    display: grid;
    align-content: center;
  }

  #nav-button-li {
    margin: 0px 0px 0 0;
    width: 0px;
  }

  button {
    padding: 1.5vh 16px;
  }

  ul {
    width: 100%;
  }

  li {
    margin-top: 4%;
  }

  #termsBody {
  overflow-wrap: break-word;    /* modern standard */
  word-wrap: break-word;        /* older name (still widely used) */
  word-break: break-word;       /* older or alternative property */

  /* Optionally let the browser hyphenate when possible */
  hyphens: auto;

  /* Ensure the text doesn’t stay on a single line */
  white-space: normal; 

  text-align: left;

  margin: 10px 10px;
  padding: 40px 35px 60px 35px;
  }
  #termsBody h1 {
    text-align: center;
  }

}

@media only screen and (max-width: 370px) {
  h1, h1 span {
    font-size: 5vh;
  }

  #nav-button {
    font-size: large;
  }
}

@media only screen and (max-width: 315px) {
  #nav-button {
    font-size: medium;
    padding: 11px 10px;
  }

  #navHeader {
    font-size: small;
  }
}

#termsBody h2 {
  padding: 3em 0 1.2em !important;
}

#termsBody h1 {
  padding-bottom: 0.4em !important;
}

#termsBody ol li {
  float: none;
}
#termsBody ul {
  font-size: inherit;
  list-style-type: inherit;
  margin: initial;
}
