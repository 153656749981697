#purchase-element {
    position: fixed;
    background: var(--colour-article-background);
    filter: drop-shadow(-5px 7px var(--colour-shadow));
    border-radius: 10px;
    padding: 10px;
    margin-right: 3%;
    width: 175px;
    height: 290px;
    right: 0;
    top: 50%;
}
#open-stripe-form-button {
    margin: auto;
}
#purchase-element h3, #purchase-element h4, #purchase-element p{
    text-align: center;
}
#purchase-element img {
    height: 87%;
    margin: auto;
    text-align: center;
    display: block;
    padding: 9px;
}