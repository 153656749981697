:root {
  --light-grey: #F6F9FC;
  --dark-terminal-color: #0A2540;
  --accent-color: #4790FF;
  --radius: 3px;
}

button, #mc-embedded-subscribe {
  background-color: var(--accent-color);
}
button, #mc-embedded-subscribe {
  background: var(--accent-color);
  color: white;
  border: 0;
  padding: 1vh 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
  border-color: var(--colour-article-background);
  border-radius: 10px;
  margin: auto;
  font-size: larger;
}
button:hover, #mc-embedded-subscribe:hover {
  background-color: #476eaa;
}
button:active, #mc-embedded-subscribe:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
button:disabled, #mc-embedded-subscribe:disabled   {
  opacity: 0.5;
  cursor: none;
}
