.visible {
    display: flex !important;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: #a0a0a06e;
    z-index: 100;
}
#payment-form {
    background-color: white;
}